<template>
  <v-form
    lazy-validation
    ref="formContact"
    class="mt-6 mt-xl-12"
    v-model="valid"
    id="formContact"
  >
    <div class="position-relative btn-overflow-div">
      <v-overflow-btn
        class="my-xl-2 btn-overflow pb-xl-12"
        target="#dropdown-example-1"
        tile
        clearable
        v-bind:append-icon="
          firstDropdown == '' || firstDropdown == undefined
            ? 'mdi-chevron-down'
            : ''
        "
        v-bind:items="departmentsGetC"
        v-bind:class="{ 'gray-color-contact': firstDropdown != '' }"
        v-model="firstDropdown"
        v-bind:rules="departmentsRules"
        hide-details="auto"
      >
      </v-overflow-btn>
      <span
        class="animation-contact titles"
        v-bind:class="{
          move: firstDropdown != '',
          not_move: firstDropdown == undefined,
        }"
        >Department</span
      >
    </div>

    <div class="fields-contact mt-4 mt-md-8 mt-xl-4">
      <v-text-field
        class="fields-contact__field"
        label="Asunto"
        v-model="subject"
        v-bind:rules="subjectRules"
        color="#000000"
        dense
        hide-details="auto"
      ></v-text-field>
    </div>

    <div class="fields-contact">
      <v-text-field
        class="fields-contact__field"
        label="Correo"
        v-model="email"
        v-bind:rules="emailRules"
        color="#000000"
        dense
        hide-details="auto"
      ></v-text-field>
    </div>

    <div class="fields-contact">
      <v-textarea
        label="Mensaje"
        no-resize
        rows="2"
        v-model="message"
        v-bind:rules="messageRules"
        color="#000000"
        hide-details="auto"
      ></v-textarea>
    </div>

    <!-- btn enviar -->
    <div
      class="btnSend-div toClick d-flex"
      style="align-items: center"
      @click="validate()"
    >
      <div>
        <v-btn text tile width="auto" class="pa-0" :disabled="!valid">
          <span class="titles not-text-transform letter-spacing-0">Enviar</span>
        </v-btn>
      </div>
      <div :class="{ 'opacity-in-views': !valid }">
        <v-img
          src="@/assets/icons/derecha.svg"
          class="ml-12 btnSend-div__arrow"
        ></v-img>
      </div>
    </div>
    <!-- /btn enviar -->

    <!-- Alerta -->
    <v-expand-transition>
      <v-snackbar
        v-if="alert"
        v-model="alert"
        v-bind:color="colorSnackbar"
        v-bind:absolute="absolute"
        v-bind:fixed="fixed"
        timeout="8000"
        top
        elevation="0"
        right
        content-class="snack-bar"
        tile
      >
        <v-row no-gutters class="fill-height" align="center" justify="center">
          <v-col cols="auto">
            <span class="titles-card-projects white--text">
              {{ textSnackbar }}
            </span>
          </v-col>
        </v-row>
      </v-snackbar>
    </v-expand-transition>
    <!-- /Alerta -->
  </v-form>
</template>

<script>
export default {
  name: "Form",
  computed: {
    departmentsGetC: function () {
      var departments = this.departmentsGet;
      var dropdownDepartments = this.departments;

      for (let index = 0; index < departments.length; index++) {
        const element = departments[index].name;
        dropdownDepartments.push(element);
      }

      return dropdownDepartments;
    },
  },
  data() {
    return {
      alert: false,
      absolute: true,
      fixed: false,
      colorSnackbar: "#000000",
      valid: false,
      textSnackbar: "Tu mensaje ha sido enviado.",
      //dropdown y atributos de departamentos
      departmentsGet: [],
      departments: [],
      firstDropdown: "",
      departmentsRules: [
        (v) => !!v || "Debes seleccionar por lo menos un departamento.",
      ],
      clearableBtn: true,
      clearableBtnFalse: false,
      //fields
      subject: "",
      subjectRules: [(v) => (v && v.length <= 100) || "Máximo 100 caracteres"],
      email: "",
      emailRules: [
        (v) => !!v || "Campo requerido",
        (v) =>
          /^[a-zA-Z0-9.-_]+@[a-zA-Z0-9.-_]+\.[a-zA-Z]+$/.test(v) ||
          "Formato no válido",
      ],
      message: "",
      messageRules: [
        (v) => !!v || "Campo requerido.",
        (v) => (v && v.length <= 500) || "Máximo 500 caracteres.",
      ],
    };
  },
  beforeCreate() {
    fetch(
      "https://www.ofisdesigncms.actstudio.xyz/api/collections/get/departments?token=e96ec97538f5b51ad80b44386e6d1c"
    )
      .then((res) => res.json())
      .then((res) => (this.departmentsGet = res.entries))
      .catch((error) => console.log(error.message));
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", this.onResize, { passive: true });
    }
  },
  methods: {
    onResize: function () {
      if (window.innerWidth <= 959) {
        this.fixed = true;
        this.absolute = false;
      } else if (window.innerWidth >= 960) {
        this.fixed = false;
        this.absolute = true;
      }
    },
    validate() {
      if (this.$refs.formContact.validate()) {
        var data = {};

        if (this.firstDropdown == "Construccion") {
          //datos hacia form 1
          data = {
            Departamento: this.firstDropdown,
            Asunto: this.subject,
            Correo: this.email,
            Mensaje: this.message,
          };

          fetch(
            "https://www.ofisdesigncms.actstudio.xyz/api/forms/submit/contact1?token=e96ec97538f5b51ad80b44386e6d1c",
            {
              method: "post",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                form: data,
              }),
            }
          )
            .then((entry) => entry.json())
            .then((entry) => console.log(entry))
            .catch((error) => console.log(error.message));

          this.alert = true;

          this.$refs.formContact.reset();
        } else if (this.firstDropdown == "Arquitectura") {
          data = {
            Departamento: this.firstDropdown,
            Asunto: this.subject,
            Correo: this.email,
            Mensaje: this.message,
          };

          fetch(
            "https://www.ofisdesigncms.actstudio.xyz/api/forms/submit/contact2?token=e96ec97538f5b51ad80b44386e6d1c",
            {
              method: "post",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                form: data,
              }),
            }
          )
            .then((entry) => entry.json())
            .then((entry) => console.log(entry))
            .catch((error) => console.log(error.message));

          this.alert = true;

          this.$refs.formContact.reset();
        } else if (this.firstDropdown == "Diseno") {
          data = {
            Departamento: this.firstDropdown,
            Asunto: this.subject,
            Correo: this.email,
            Mensaje: this.message,
          };

          fetch(
            "https://www.ofisdesigncms.actstudio.xyz/api/forms/submit/contact3?token=e96ec97538f5b51ad80b44386e6d1c",
            {
              method: "post",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                form: data,
              }),
            }
          )
            .then((entry) => entry.json())
            .then((entry) => console.log(entry))
            .catch((error) => console.log(error.message));

          this.alert = true;

          this.$refs.formContact.reset();
        }
      }
    },
  },
};
</script>