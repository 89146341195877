<template>
  <v-container fluid class="py-0 animation" style="animation-delay: 2s;" v-if="render">
    <v-row>
      <v-col class="pa-0" cols="12" md="6">
        <template v-if="contactSingleton.cover != null">
          <v-img
            :src="
              'https://www.ofisdesigncms.actstudio.xyz/' +
              contactSingleton.cover.path
            "
            class="Contact__img"
          ></v-img>
        </template>
      </v-col>

      <v-col cols="12" md="6" class="Contact__column-2 position-relative padding-custom-contact">
        <v-row no-gutters justify="center" class="fill-height">
          <v-col cols="12" sm="10" class="px-8 px-sm-12 px-md-3">
            <span class="d-block titles aeonik-bold"> Let´s get in touch </span>

            <!-- Form -->
            <Form></Form>
            <!-- /Form -->
          </v-col>

          <v-col cols="12" sm="10" class="px-8 px-sm-12 px-md-3">
            <v-row
              class="Contact__contact-div"
              no-gutters
              justify="space-between"
              align="center"
            >
              <v-col cols="auto">
                <a
                  :href="'mailto:' + contactSingleton.card_contact.email"
                  class="not-text-decoration color-black"
                >
                  <span class="d-block titles-card-projects">{{
                    contactSingleton.card_contact.email
                  }}</span>
                </a>

                <a
                  target="_blank"
                  :href="'tel:' + contactSingleton.card_contact.phone_redirect"
                  class="not-text-decoration color-black"
                >
                  <span class="d-block titles-card-projects"
                    >T. {{ contactSingleton.card_contact.phone }}</span
                  >
                </a>

                <a
                  target="_blank"
                  :href="contactSingleton.card_contact.address_redirect"
                  class="not-text-decoration color-black"
                >
                  <span class="d-block titles-card-projects">{{
                    contactSingleton.card_contact.address
                  }}</span>
                </a>
              </v-col>

              <v-col cols="auto">
                <a :href="'https://wa.me/+521'+contactSingleton.card_contact.celphone" target="_blank">
                  <v-img src="@/assets/icons/wpp-logo.svg" class="wpp-logo"></v-img>
                </a>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Form from "../components/Statics/Form";

export default {
  components: {
    Form,
  },
  data() {
    return {
      //hacer un render hasta que cargue bien el fetch
      render: false,
      //data del singleton
      contactSingleton: {},
    };
  },
  beforeCreate() {
    //fetch para el singleton de contacto
    fetch(
      "https://www.ofisdesigncms.actstudio.xyz/api/singletons/get/contact?token=e96ec97538f5b51ad80b44386e6d1c"
    )
      .then((data) => data.json())
      .then((data) => (this.contactSingleton = data))
      .then((data) => (this.render = true))
      .catch((error) => console.log(error.message));
  },
  mounted() {
    this.$emit("update", 1);
  },
};
</script>